import VueRouter from "vue-router";
import UserStore from "@/store/user.store";

import Login from "@/views/Login";
import Dashboard from "@/views/Dashboard";

import EventList from "@/components/EventComponents/EventList";
import EventEdit from "@/components/EventComponents/EventEdit";
import EventPreview from "@/components/EventComponents/EventPreview";

import RoomList from "@/components/RoomComponents/RoomList";
import RoomEdit from "@/components/RoomComponents/RoomEdit";

import UserList from "@/components/UserComponents/UserList";
import UserEdit from "@/components/UserComponents/UserEdit";

import DeleteObject from "@/components/DeleteObject";

var router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: "/g-oauth/login",
      name: "g-oauth",
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: "/",
      name: "dashboard",
      component: Dashboard,
      meta: {
        authenticated: true
      },
      children: [
        // Event Components
        {
          path: "/event",
          name: "eventList",
          component: EventList
        },
        {
          path: "/event/new",
          name: "createEvent",
          component: EventEdit
        },
        {
          path: "/event/:id",
          name: "editEvent",
          component: EventEdit
        },
        {
          path: "/preview",
          name: "previewEvent",
          component: EventPreview
        },
        // Room Components
        {
          path: "/room",
          name: "roomList",
          component: RoomList
        },
        {
          path: "/room/new",
          name: "createRoom",
          component: RoomEdit
        }, 
        {
          path: "/room/:id",
          name: "editRoom",
          component: RoomEdit
        }, 
        // User Components
        {
          path: "/user",
          name: "userList",
          component: UserList
        },
        {
          path: "/user/new",
          name: "createUser",
          component: UserEdit
        },
        {
          path: "/user/:id",
          name: "editUser",
          component: UserEdit
        },
        {
          path: "/:objectType/:id/delete",
          name: "deleteObject",
          component: DeleteObject,
          props: true
        },
      ] 
    }
  ]
});


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.authenticated)) {
    if (UserStore.getters.isLoggedIn) {
      if (to.path == "/"){
        next("/event")
        return
      } else {
        next()
        return
      }
    }
    next('/login') 
  } else {
    next() 
  }
})

export default router;
