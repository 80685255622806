<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title">{{ objectTypeName }}: "{{ object.name }}" löschen?</div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <p>Bitte bestätige das Löschen von {{ objectTypeName }} "{{ object.name }}":</p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <form @submit="submitForm">
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="confirmDelete" v-model="confirmDelete">
            <label class="form-check-label" for="confirmDelete">Löschen bestätigen</label>
          </div>
          <button type="submit" class="btn btn-danger" :disabled="!confirmDelete">Löschen</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['object'],
  data(){
    return {
      objectType: this.$route.params.objectType,
      objectTypeName: this.getObjectTypeName(this.$route.params.objectType),
      objectId: this.$route.params.id,
      confirmDelete: false
    }
  },

  methods: {
    submitForm: function (e) {
      if (this.confirmDelete) {
        this.deleteObject()
      }
      e.preventDefault();
    },

    getObjectTypeName: function(objectType) {
      switch (objectType) {
        case "event":
          return "Event"
        case "room":
          return "Raum"
        case "user":
          return "Nutzer"
        default:
          break;
      }  
    },

    deleteObject: function(){
      this.$http.delete("/" + this.objectType + "/" + this.objectId)
      .then(() => {
        this.$router.push({name: this.objectType + "List"});
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })

      if (this.objectType == "event"){
        this.$http.delete(this.object.image_url)
        .then(() => {})
        .catch(err => {
          console.log(err)
        })
        .finally(() => {

        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  padding: 0.7rem 1rem;
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  border-bottom: 2px solid #000;
}

p {
  margin-bottom: 0rem;
}
</style>