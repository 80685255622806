import axios from "axios";
import UserStore from "@/store/user.store"
import router from "../router"

const ApiService = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    axios.defaults.withCredentials = true
    
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        if (error.request.status == 401) {
          if (error.config.url.includes('/refresh')) {
            // Refresh token has failed. Logout the user
            UserStore.dispatch('logout')
            router.push({name: "login"});
            
            throw error
          } 
          else {
            // Refresh the access token
            try{
              await UserStore.dispatch('refresh')
              // Retry the original request
              return this.customRequest({
                method: error.config.method,
                url: error.config.url,
                data: error.config.data
              })
            } 
            catch (e) {
              // Refresh has failed - reject the original request
              throw error
            }
          }
        }
        // If error was not 401 just reject as is
        throw error
      }
    );
  },

  setHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${UserStore.getters.getToken}`
  },

  removeHeader() {
      axios.defaults.headers.common = {}
  },

  get(resource, params) {
      return axios.get(resource, params)
  },

  post(resource, data) {
      return axios.post(resource, data)
  },

  put(resource, data) {
      return axios.put(resource, data)
  },

  delete(resource) {
      return axios.delete(resource)
  },

  /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
  customRequest(data) {
    return axios(data)
  }
}

export default ApiService