<template>
  <div id="sidebar" class="bg-light shadow-lg">
    <div class="nav-title shadow-sm bg-dark text-light">Navigation</div>
    <ul class="nav flex-column text-secondary">
      <li class="nav-item">    
        <router-link to="/event" class="nav-link">
          <font-awesome-icon :icon="['fas', 'calendar-day']" />Events
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/room" class="nav-link">
          <font-awesome-icon :icon="['fas', 'building']" />Räume
        </router-link>
      </li>
      <li class="nav-item" :class="{ 'd-none': !isAdmin }">
        <router-link to="/user" class="nav-link">
          <font-awesome-icon :icon="['fas', 'user']" />Nutzer
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/preview" class="nav-link">
          <font-awesome-icon :icon="['fas', 'desktop']" />Preview
        </router-link>
      </li>
    </ul>
    <div class="user-actions">
      <div class="row">
        <div class="col-md-8 nopadding align-self-center">
          <font-awesome-icon :icon="['fas', 'user-circle']" />{{ username }}
        </div>
        <div class="col-md-4 nopadding align-self-center">
          <button v-on:click="logout" class="btn btn-danger">Logout</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    username: function() {
      return this.$store.state.user.username
    },
    isAdmin: function() {
      return this.$store.getters.isAdmin 
    }
  },
  methods: {
    logout: function() {
      this.$store.dispatch('logout')
      .then(() => {
        this.$router.push("/").catch(err => void err)
      })
      .catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  vertical-align: text-top;
}

#sidebar {
  height: 100%;
  border-radius: 1rem 0 0 1rem;
  overflow: hidden;
}

.nav {
  align-items: center;
}

.nav-title {
  padding: 0.7rem 1rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.user-actions {
  position: absolute;
  padding: 0.7rem 2rem;
  text-align: left;
  font-size: 1.4rem;
  bottom: 0;
  width: 100%;
}

.user-actions svg {
  font-size: 2rem;
  margin: 0px 10px;
  vertical-align: bottom;
}

.nav-link {
  padding: 0.8rem 1rem;
}

.nav-link svg{
  margin: 0px 10px;
}

.nav-item {
  font-size: 18px;
  text-align: left;
  width: 100%;
}

.nav-item a {
  color: inherit;
  text-decoration: none;
}

.nav-item a:hover {
  color: inherit;
  background-color: #d7dbde;
  text-decoration: none;
}

.nav-item a:visited {
  color: inherit;
  background-color: #d7dbde;
  text-decoration: none;
}

.router-link-active {
  color: #000 !important;
  background-color: #d7dbde;
  text-decoration: none;
}

</style>