<template>
  <div class="container h-100 d-flex flex-column">
    <div class="row">
      <div class="col">
        <div class="title" v-if="eventId != undefined">Event {{ eventId }} bearbeiten</div>
        <div class="title" v-else>Neues Event erstellen</div>
      </div>
    </div>
    <div class="row mt-3 flex-grow-1">
      <div class="col overflow-auto">
        <div class="container form-container">
          <form @submit="submitForm">
            <div class="form-group">
              <label for="inputEventName">Name:</label>
              <div class="row">
                <div class="col-4">
                  <input type="text" class="form-control" id="inputEventName" v-model="inputEventName" maxlength="25" required>
                  <small id="inputEventNameHelp" class="form-text text-muted">Max. 25 Zeichen erlaubt</small>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="inputEventLogo">Logo:</label>
              <div class="row">
                <div class="col-6">
                  <img id="uploadPreview">
                  <div class="custom-file">
                    <input type="file" ref="image" class="custom-file-input" id="inputEventLogo" v-on:change="handleFileUpdate()">
                    <label class="custom-file-label" for="inputEventLogo">Wähle Datei aus</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="inputSelectRoom">Raum:</label>
              <div class="row">
                <div class="col-3">
                  <select class="form-control" id="inputSelectRoom" v-model="inputEventRoom" required>
                    <option value="" disabled>Wähle Raum</option>
                    <option :value="room.id" v-for="(room, key) in eventRooms" v-bind:key="key">{{ room.name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="inputBeginDate">Anfang:</label>
                <div class="row">
                  <div class="col-3">
                    <input type="date" class="form-control" id="inputBeginDate" v-model="inputBeginDate" aria-describedby="inputBeginDateHelp" required>
                    <small id="inputBeginDateHelp" class="form-text text-muted">Datum</small>
                  </div>
                  <div class="col-3">
                    <input type="time" class="form-control" id="inputBeginTime" v-model="inputBeginTime" aria-describedby="inputBeginTimeHelp" required>
                    <small id="inputBeginTimeHelp" class="form-text text-muted">Uhrzeit</small>
                  </div>
                </div>
            </div>
            <div class="form-group">
              <label for="inputEndDate">Ende:</label>
                <div class="row">
                  <div class="col-3">
                    <input type="date" class="form-control" id="inputEndDate" v-model="inputEndDate" aria-describedby="inputEndDateHelp" required>
                    <small id="inputEndDateHelp" class="form-text text-muted">Datum</small>
                  </div>
                  <div class="col-3">
                    <input type="time" class="form-control" id="inputEndTime" v-model="inputEndTime" aria-describedby="inputEndTimeHelp" required>
                    <small id="inputEndTimeHelp" class="form-text text-muted">Uhrzeit</small>
                  </div>
                </div>
            </div>
            <div class="form-group">
              <label for="inputSelectDirection">Richtung:</label>
              <div class="row">
                <div class="col-2">
                  <select class="form-control" id="inputSelectDirection" v-model="inputDirection" required>
                    <option value="left">Links</option>
                    <option value="right">Rechts</option>
                  </select>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary mb-3">{{ buttonAction }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    const eventId = this.$route.params.id;

    if (eventId == undefined) {
      return {
        eventId,
        eventRooms: {},
        inputEventName: "",
        inputEventLogo: null,
        inputEventRoom: "",
        inputBeginDate: this.getDate(+new Date / 1000),
        inputBeginTime: this.getTime(+new Date / 1000),
        inputEndDate: this.getDate(+new Date / 1000),
        inputEndTime: "",
        inputDirection: "left",
        buttonAction: "Erstellen"
      } 
    } else {
      return {
        eventId,
        eventRooms: {},
        inputEventName: "",
        inputEventLogo: null,
        inputEventRoom: "",
        inputBeginDate: this.getDate(+new Date / 1000),
        inputBeginTime: this.getTime(+new Date / 1000),
        inputEndDate: this.getDate(+new Date / 1000),
        inputEndTime: "",
        inputDirection: "left",
        buttonAction: "Speichern"
      }
    }
  },

  created() {
    this.getRooms()

    if (this.eventId != undefined){
      this.getEvent(this.$route.params.id)
    }
  },

  methods: {
    submitForm: async function(e) {
      e.preventDefault()
              
      var imageURL = undefined
      if (this.inputEventLogo) {
        if (process.env.VUE_APP_ENV == "local") {
          imageURL = "http://" + process.env.VUE_APP_URL + "/api/v1/static/" + this.inputEventLogo.name
        } else {
          imageURL = "https://" + process.env.VUE_APP_URL + "/api/v1/static/" + this.inputEventLogo.name
        }  
      }
      

      const data = {
        "name": this.inputEventName,
        "room": parseInt(this.inputEventRoom),
        "direction": this.inputDirection,
        "begin": this.getTimestamp(this.inputBeginDate + " " + this.inputBeginTime + ":00"),
        "end": this.getTimestamp(this.inputEndDate + " " + this.inputEndTime + ":00"),
        "image_url": imageURL
      }

      if(this.buttonAction == "Erstellen"){
        if (this.inputEventLogo) await this.uploadFile()
        this.createEvent(data)
      } else if (this.buttonAction == "Speichern"){
        if (this.inputEventLogo) await this.uploadFile()
        this.updateEvent(data)
      }
    },

    getEvent: function(eventId) {
      this.$http.get("/event/" + eventId)
      .then(resp => {
        this.inputEventName = resp.data.event.name
        this.inputEventRoom = resp.data.event.room
        this.inputDirection = resp.data.event.direction
        this.inputBeginDate = this.getDate(resp.data.event.begin)
        this.inputBeginTime = this.getTime(resp.data.event.begin)
        this.inputEndDate = this.getDate(resp.data.event.end)
        this.inputEndTime = this.getTime(resp.data.event.end)

        this.getImage(resp.data.event.image_url)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })
    },
  
    createEvent: function(data) {
      this.$http.post("/event", data)
      .then(() => {
        this.$router.push({name: "eventList"});
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })
    },
    
    updateEvent: function(data) {
      this.$http.put("/event/" + this.eventId, data)
      .then(() => {
        this.$router.push({name: "eventList"});
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })
    },

    getRooms: function() {
      this.$http.get("/room")
      .then(resp => {
        this.eventRooms = resp.data.rooms
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })
    },

    handleFileUpdate: function() {
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.svg)$/i;
      if (allowedExtensions.exec(this.$refs.image.files[0].name)) {
        this.inputEventLogo = this.$refs.image.files[0];

        var oFReader = new FileReader()

        oFReader.onload = (oFREvent => {
          document.getElementById("uploadPreview").src = oFREvent.target.result
          document.getElementById("uploadPreview").style = "display: inherit;"
          document.getElementsByClassName("custom-file-label")[0].innerText = this.inputEventLogo.name
        })

        oFReader.readAsDataURL(this.inputEventLogo)
      } else {
        alert('Nur folgende Dateitypen sind erlaubt: JPEG, PNG, SVG');
      } 
    },

    uploadFile: function() {
      let formData = new FormData();
      formData.append('image', this.inputEventLogo);

      return this.$http.post('/upload',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(() => {
      })
      .catch(err => {
        console.log(err)
      });
    },

    getImage: function(url) {
      this.$http.get(url, {responseType: 'blob'})
      .then(resp => {
        var oFReader = new FileReader()

        oFReader.onload = (oFREvent => {
          document.getElementById("uploadPreview").src = oFREvent.target.result
          document.getElementById("uploadPreview").style = "display: inherit;"
          document.getElementsByClassName("custom-file-label")[0].innerText = this.inputEventLogo.name
        })

        this.inputEventLogo = new File([resp.data], url.split('/').pop(), {type: resp.data.type})
        oFReader.readAsDataURL(resp.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })
    },

    getDate: function(timestamp) {
      var date = new Date(timestamp * 1000);

      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();

      date = yyyy + '-' + mm + '-' + dd;

      return date;
    },

    getTime: function(timestamp) {
      var date = new Date(timestamp * 1000);
      var time = ("0" + date.getHours()).slice(-2) + ":" + (date.getMinutes()<10?'0':'') + date.getMinutes();

      return time;
    },

    getTimestamp: function(string) {
      string.replace(/-/g,'/')
      return Math.round(new Date(string).getTime()/1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.overflow-auto {
  overflow-x: hidden !important;
}

.title {
  padding: 0.7rem 1rem;
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  border-bottom: 2px solid #000;
}

.form-container {
  position: absolute;
}

#uploadPreview {
  max-height: 10rem;
  margin-bottom: 1rem;
}

.custom-file-label::after {
  content: "Durchsuchen";
}
</style>