<template>
  <div class="container h-100 d-flex flex-column">
    <div class="row">
      <div class="col">
        <div class="title">Alle Events</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button v-on:click="createEvent" class="btn btn-primary">
          <span class="align-middle">Neu</span>
          <font-awesome-icon :icon="['fas', 'plus']" />
        </button>
      </div>
    </div>
    <div class="row flex-grow-1">
      <div class="col d-flex flex-column">
        <div class="table-responsive flex-grow-1">
          <table class="table table-hover">
            <thead class="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th v-for="key in keys.slice(1)" v-bind:key="key">{{ key }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(event, key) in events" v-bind:key="key">
                <th scope="row">{{ event.id }}</th>
                <td>{{ event.name }}</td>
                <td>{{ event.room }}</td>
                <td>{{ convertTimestamp(event.begin) }}</td>
                <td>{{ convertTimestamp(event.end) }}</td>
                <td>
                  <span v-if="event.status == 'active'" class="badge badge-success">Aktiv</span>
                  <span v-if="event.status == 'planned'" class="badge badge-secondary">Geplant</span>
                </td>
                <td>
                  <router-link :to="{ name: 'editEvent', params: { id: event.id }}" class="editButton">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </router-link>
                  <router-link :to="{ name: 'deleteObject', params: { id: event.id, objectType: 'event', object: event }}" class="deleteButton">
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      keys: ["Id", "Name", "Raum", "Von", "Bis", "Status", "Aktionen"],  
      events: []
    }
  },

  created() {
    this.$http.get("/event")
    .then(resp => {
      this.events = resp.data.events

      if (this.events != null){
        this.events.forEach(event => {
          this.getRoomName(event.id, event.room)
        })
      }
    })
    .catch(err => {
      console.log(err)
    })
    .finally(() => {

    })
  },

  methods: {
    createEvent: function() {
      this.$router.push({ name: "createEvent" });
    },

    convertTimestamp: function(timestamp) {
      var date = new Date(timestamp * 1000)
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!

      var datum = dd + '.' + mm + '.'
      var time = ("0" + date.getHours()).slice(-2) + ":" + (date.getMinutes()<10?'0':'') + date.getMinutes();

      return datum + ' ' + time;
    },

    getRoomName: function(eventId, roomId) {
      this.$http.get("/room/" + roomId)
      .then(resp => {
        this.events.find(event => event.id == eventId).room = resp.data.room.name
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })  
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  padding: 0.7rem 1rem;
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  border-bottom: 2px solid #000;
}

.btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.btn svg {
  margin-left: 5px;
}

.table-responsive {
  flex-basis: inherit;
  will-change: transform;
}

.table thead th {
  box-shadow: 0px -2px #454d55, 0px 2px #454d55;
  position: sticky;
  top: 0;
}

.table tbody td svg {
  margin: 0px 5px;
}

.table tbody td a {
  color: #000;
}

.table tbody td a.editButton:hover {
  color: $primary;
}

.table tbody td a.deleteButton:hover {
  color: $danger;
}

.badge {
  font-size: 85%;
}
</style>