<template>
  <div id="dashboard" class="container d-flex flex-grow-1 flex-column">
    <div class="row">
      <div class="col-md-3">
        <Logo></Logo>
      </div>
      <div class="col-md-9"></div>
    </div>
    <div class="row flex-grow-1">
      <div class="col-md-3 nopadding">
        <Sidebar></Sidebar>
      </div>
      <div class="col-md-9 nopadding">
        <div class="wrapper shadow-lg">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import Sidebar from "@/components/Sidebar";

export default {
  components: {
    Logo,
    Sidebar
  }
}
</script>

<style lang="scss">
#dashboard {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.wrapper {
  height: 100%;
  background-color: #fff;
  border-radius: 0 1rem 1rem 0;
}

.nopadding {
  margin: 0 !important;
  padding: 0 !important;  
}
</style>