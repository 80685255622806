<template>
  <div class="container h-100 d-flex flex-column">
    <div class="row">
      <div class="col">
        <div class="title">Alle Nutzer</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button v-on:click="createUser" class="btn btn-primary">
          <span class="align-middle">Neu</span>
          <font-awesome-icon :icon="['fas', 'plus']" />
          </button>
      </div>
    </div>
    <div class="row flex-grow-1">
      <div class="col d-flex flex-column">
        <div class="table-responsive flex-grow-1">
          <table class="table table-hover">
            <thead class="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th v-for="key in keys.slice(1)" v-bind:key="key">{{ key }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, key) in users" v-bind:key="key">
                <th scope="row">{{ user.uid }}</th>
                <td>{{ user.username }}</td>
                <td>{{ user.role }}</td>
                <td v-if="user.username != 'admin'">
                  <router-link :to="{ name: 'editUser', params: { id: user.uid }}" class="editButton">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </router-link>
                  <router-link :to="{ name: 'deleteObject', params: { id: user.uid, objectType: 'user', object: {name: user.username} }}" class="deleteButton">
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      keys: ["Id", "Nutzername", "Rolle", "Aktionen"],  
      users: []
    }
  },
  created() {
    this.$http.get("/user")
    .then(resp => {
      this.users = resp.data.users
    })
    .catch(err => {
      console.log(err)
    })
    .finally(() => {

    })
  },
  methods: {
    createUser: function() {
      this.$router.push({name: "createUser"});
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  padding: 0.7rem 1rem;
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  border-bottom: 2px solid #000;
}

.btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.btn svg {
  margin-left: 5px;
}

.table-responsive {
  flex-basis: inherit;
  will-change: transform;
}

.table thead th {
  box-shadow: 0px -2px #454d55, 0px 2px #454d55;
  position: sticky;
  top: 0;
}

.table tbody td svg {
  margin: 0px 5px;
}

.table tbody td a {
  color: #000;
}

.table tbody td a.editButton:hover {
  color: $primary;
}

.table tbody td a.deleteButton:hover {
  color: $danger;
}

.badge {
  font-size: 85%;
}
</style>