import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import api from "@/services/api.service";

import jwtDecode from "jwt-decode";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('access_token') || '',
    tokenPayload: {},
    user: {},
  },

  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, data){
      state.status = 'success'
      state.token = data.token
      state.tokenPayload = Object.assign({}, state.tokenPayload, data.tokenPayload)
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
      state.tokenPayload = {} 
      state.user = {}
    },
    userData(state, user){
      state.user = Object.assign({}, state.user, user)
    },
    error(state){
      state.status = 'error'
    }
  },
  actions: {
    login({commit, getters}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')

        api.post("/login", user)
        .then(resp => {
          localStorage.setItem('access_token', resp.data.access_token)
        
          const token = resp.data.access_token
          const tokenPayload = jwtDecode(token)

          commit('auth_success', { token, tokenPayload}) 
          api.setHeader()

          // Get UserData on successful login
          api.get("/user/" + getters.getTokenPayload.uid)
          .then(resp => {
            commit('userData', resp.data.user)
          })
          .catch(err => {
            commit('error')
            reject(err)
          })
          
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    finishGoogleLogin({commit, getters}, key) {
      return new Promise((resolve, reject) => {
        api.get("/g-oauth/complete?code=" + key)
        .then(resp => {
          localStorage.setItem('access_token', resp.data.access_token)
        
          const token = resp.data.access_token
          const tokenPayload = jwtDecode(token)

          commit('auth_success', { token, tokenPayload}) 
          api.setHeader()

          // Get UserData on successful login
          api.get("/user/" + getters.getTokenPayload.uid)
          .then(resp => {
            commit('userData', resp.data.user)
          })
          .catch(err => {
            commit('error')
            reject(err)
          })
        
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      });
    },
    logout({commit}){
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('access_token')
        document.cookie = "refresh_token=; Path=/api/v1/refresh; Domain="+ process.env.APP_URL +"; expires=Thu, 01 Jan 1970 00:00:01 GMT"
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    refresh({commit, getters}){
      return new Promise((resolve, reject) => {
        commit('auth_request')

        api.post("/refresh")
        .then(resp => {
          localStorage.setItem('access_token', resp.data.access_token)

          const token = resp.data.access_token
          const tokenPayload = jwtDecode(token)

          commit('auth_success', { token, tokenPayload}) 
          api.setHeader()

          // Get UserData on successful login
          api.get("/user/" + getters.getTokenPayload.uid)
          .then(resp => {
            commit('userData', resp.data.user)
          })
          .catch(err => {
            commit('error')
            reject(err)
          })
          
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    isAdmin: state => {
      if (state.tokenPayload.role == "admin"){
        return true
      } else {
        return false
      }
    },
    getToken: state => {
      return state.token;
    },
    authError: (state, getters) => {
      if (getters.authStatus == "error"){
        return true
      } else {
        return false
      }
    },
    getTokenPayload: state => {
      return state.tokenPayload
    },
    getUser: state => {
      return state.user
    }
  }
})
