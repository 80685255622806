<template>
  <div id="login" class="container h-100">
    <div class="row h-100 align-items-center">
      <div class="col-md"></div>
      <div class="col-md">
        <!-- TODO: Import Header Component-->
        <Logo></Logo>
        <div class="card shadow-lg p-3 mb-5 bg-white">
          <div class="card-body">
            <h3 class="card-title">Login</h3>
            <div class="alert alert-danger" role="alert" v-bind:class="{ 'd-none': !authError }">
             {{ errorMessage }}
            </div>
            <form @submit="login">
              <div class="form-group">
                <label for="inputUsername">Nutzername</label>
                <input type="text" class="form-control" id="inputUsername" v-model="username">
              </div>
              <div class="form-group">
                <label for="inputPassword">Passwort</label>
                <input type="password" class="form-control" id="inputPassword" v-model="password">
              </div>
              <button v-if="!loading"  type="submit" class="btn btn-primary">Login</button>
              <button v-if="!loading" style="margin-left: 10px;" class="btn btn-secondary" onclick="location.href = '/api/v1/g-oauth'">Google-Login</button>
              <button v-if="loading" class="btn btn-primary" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Loading...</span>
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md"></div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";

export default {
  data: function () {
    return {
      username: "",
      password: "",
      authError: false,
      errorMessage: "",
      loading: false
    }
  },
  components: {
    Logo
  },
  methods: {
    login: function(e) {
      e.preventDefault()
      
      this.loading = true

      var username = this.username
      var password = this.password

      this.$store.dispatch('login', { username: username, password: password })
      .then(() => {
        this.loading = false
        this.$router.push("/").catch(err => void err)
      })
      .catch(err => {
        this.loading = false
        this.authError = this.$store.getters.authError;

        if (err.response != undefined) {
          this.errorMessage = err.response.data.error;
        } else {
          this.errorMessage = err.message;
        }
      })
    },
    googleauth: function() {
      if (this.$route.name === "g-oauth") {
        this.loading = true;
        this.$store.dispatch('finishGoogleLogin', this.$route.query.code)
        .then(() => {
          this.loading = false
          this.$router.push("/").catch(err => void err)
        })
        .catch(() => {
          this.loading = false
          this.errorMessage = "Google Login fehlgeschalgen!"
        })
      }
    }
  },
  mounted() {
    this.googleauth()
  }
};
</script>

<style lang="scss" scoped>
.card {
  height: fit-content;
  border-radius: 1rem;
}
</style>
