import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";

// Import styles and icons
import "bootstrap";
import { FontAwesomeIcon } from "./icons";
Vue.component("font-awesome-icon", FontAwesomeIcon);

// Import store and services
import store from "@/store/user.store";
import ApiService from "@/services/api.service";

if (process.env.VUE_APP_ENV == "local") {
  ApiService.init("http://" + process.env.VUE_APP_URL + "/api/v1")
} else {
  ApiService.init("https://" + process.env.VUE_APP_URL + "/api/v1")
}


if (store.getters.getToken) {
  ApiService.setHeader()
  store.dispatch('refresh')
}

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.prototype.$http = ApiService;

new Vue({
  render: h => h(App),
  router, 
  store
}).$mount("#app");
