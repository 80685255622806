<template>
  <div class="container h-100 d-flex flex-column">
    <div class="row">
      <div class="col">
        <div class="title" v-if="userId != undefined">Nutzer {{ userId }} bearbeiten</div>
        <div class="title" v-else>Neuen Nutzer erstellen</div>
      </div>
    </div>
    <div class="row mt-3 flex-grow-1">
      <div class="col overflow-auto">
        <div class="container form-container">
          <form @submit="submitForm">
            <div class="form-group">
              <label for="inputUsername">Nutzername:</label>
              <div class="row">
                <div class="col-4">
                  <input type="text" class="form-control" id="inputUsername" v-model="inputUsername" required>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="inputSelectRole">Rolle:</label>
              <div class="row">
                <div class="col-2">
                  <select class="form-control" id="inputSelectRole" v-model="inputSelectRole" required>
                    <option value="admin">Admin</option>
                    <option value="editor">Editor</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="inputPassword">Passwort:</label>
              <div class="row">
                <div class="col-4">
                  <input type="password" class="form-control" id="inputPassword" v-model="inputPassword" :required="(buttonAction == 'Erstellen')">
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="inputPasswordRepeat">Passwort wiederholen:</label>
              <div class="row">
                <div class="col-4">
                  <input type="password" class="form-control" id="inputPasswordRepeat" v-model="inputPasswordRepeat" :required="(buttonAction == 'Erstellen')">
                </div>
              </div>
              <small class="passwordNotice" :class="{ 'd-none': confirmPasswords}">Die Passwörter müssen übereinstimmen!</small>
            </div>
            <button type="submit" class="btn btn-primary mb-3" :disabled="!confirmPasswords">{{ buttonAction }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    const userId = this.$route.params.id;

    if (userId == undefined) {
      return {
        userId,
        inputUsername: "",
        inputSelectRole: "editor",
        inputPassword: "",
        inputPasswordRepeat: "",
        buttonAction: "Erstellen",
      } 
    } else {
      //TODO, Load Event Data and put into Forms
      return {
        userId,
        inputUsername: "",
        inputSelectRole: "editor",
        inputPassword: "",
        inputPasswordRepeat: "",
        buttonAction: "Speichern",
      }
    }
  },
  created() {
    if (this.userId != undefined){
      this.getUser(this.$route.params.id)
    }
  },
  computed: {
    confirmPasswords: function() {
      if (this.inputPassword == this.inputPasswordRepeat){
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    submitForm: function(e) {
      e.preventDefault()

      const data = {
        "username": this.inputUsername,
        "password": this.inputPassword,
        "role": this.inputSelectRole
      }

      if(this.buttonAction == "Erstellen"){
        this.createUser(data)
      } else if (this.buttonAction == "Speichern"){
        this.updateUser(data)
      }
    },

    getUser: function(userId) {
      this.$http.get("/user/" + userId)
      .then(resp => {
        this.inputUsername = resp.data.user.username
        this.inputSelectRole = resp.data.user.role
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })
    },

    createUser: function(data) {
      this.$http.post("/user", data)
      .then(() => {
        this.$router.push({name: "userList"});
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })
    },

    updateUser: function(data) {
      this.$http.put("/user/" + this.userId, data)
      .then(() => {
        this.$router.push({name: "userList"});
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })
    }
  }
}
</script>

<style lang="scss" scoped>
.overflow-auto {
  overflow-x: hidden !important;
}

.title {
  padding: 0.7rem 1rem;
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  border-bottom: 2px solid #000;
}

.form-container {
  position: absolute;
}

.passwordNotice {
  color: $danger;
}
</style>