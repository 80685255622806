<template>
  <div class="container h-100 d-flex flex-column">
    <div class="row">
      <div class="col">
        <div class="title">Alle Räume</div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button v-on:click="createRoom" class="btn btn-primary">
          <span class="align-middle">Neu</span>
          <font-awesome-icon :icon="['fas', 'plus']" />
          </button>
      </div>
    </div>
    <div class="row flex-grow-1">
      <div class="col d-flex flex-column">
        <div class="table-responsive flex-grow-1">
          <table class="table table-hover">
            <thead class="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th v-for="key in keys.slice(1)" v-bind:key="key">{{ key }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(room, key) in rooms" v-bind:key="key">
                <th scope="row">{{ room.id }}</th>
                <td>{{ room.name }}</td>
                <td>{{ room.room_id }}</td>
                <td>{{ getFloor(room.floor) }}</td>
                <td>
                  <router-link :to="{ name: 'editRoom', params: { id: room.id }}" class="editButton">
                    <font-awesome-icon :icon="['fas', 'edit']" />
                  </router-link>
                  <router-link :to="{ name: 'deleteObject', params: { id: room.id, objectType: 'room', object: room }}" class="deleteButton">
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      keys: ["Id", "Name", "Raum ID", "Etage", "Aktionen"],  
      rooms: []
    }
  },

  created() {
    this.$http.get("/room")
    .then(resp => {
      this.rooms = resp.data.rooms
    })
    .catch(err => {
      console.log(err)
    })
    .finally(() => {

    })
  },

  methods: {
    createRoom: function() {
      this.$router.push({
        name: "createRoom"
      });
    },

    getFloor(floorId){
      switch (floorId) {
        case 9:
          return "Untergeschoss"
        case 10:
          return "Erdgeschoss"
        case 11:
          return "1. Etage"
        case 12:
          return "2. Etage"
        case 13:
          return "3. Etage"
        case 14:
          return "4. Etage"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  padding: 0.7rem 1rem;
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  border-bottom: 2px solid #000;
}

.btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.btn svg {
  margin-left: 5px;
}

.table-responsive {
  flex-basis: inherit;
  will-change: transform;
}

.table thead th {
  box-shadow: 0px -2px #454d55, 0px 2px #454d55;
  position: sticky;
  top: 0;
}

.table tbody td svg {
  margin: 0px 5px;
}

.table tbody td a {
  color: #000;
}

.table tbody td a.editButton:hover {
  color: $primary;
}

.table tbody td a.deleteButton:hover {
  color: $danger;
}

.badge {
  font-size: 85%;
}
</style>