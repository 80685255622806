<template>
  <div class="container d-flex flex-column h-100">
    <div class="row">
      <div class="col">
        <div class="title">Preview</div>
      </div>
    </div>
    <div id="preview" class="d-flex flex-grow-1 flex-column justify-content-center">
      <div :id="'row-' + y" class="row" v-for="y in rowElements.length" :key="y">
        <div :class="'col-' + calcColumn(rowElements[y - 1]) + ' ' + x" v-for="x in rowElements[y - 1]" :key="x">
          <div class="card shadow-lg">
            <img class="card-img-top" v-bind:src="renderEventByPosition(x,y).image_url">
            <div class="card-body">
              <h3 class="card-title text-center">{{ renderEventByPosition(x,y).name }}</h3>
              <div class="card-text">
                <h5> Raum: </h5> 
                <span>{{ renderEventByPosition(x,y).room }} </span>
              </div>
              <div class="card-text">
                <h5> Von: </h5> 
                <span>{{ convertTimestamp(renderEventByPosition(x,y).begin) }}</span>
              </div>
              <div class="card-text">
                <h5> Bis: </h5> 
                <span>{{ convertTimestamp(renderEventByPosition(x,y).end) }}</span>
              </div>
              <div class="direction text-center">
                <font-awesome-icon v-if="renderEventByPosition(x,y).direction == 'right'"  :icon="['fas', 'long-arrow-alt-right']" />
                <font-awesome-icon v-if="renderEventByPosition(x,y).direction == 'left'"  :icon="['fas', 'long-arrow-alt-left']" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-2 offset-10">
        <button v-on:click="savePositions" class="btn btn-primary">
          <span class="align-middle">Speichern</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Sortable, Swap } from 'sortablejs';
Sortable.mount(new Swap())

export default {
  data: function() {
    return {
      loadedEventPositions: [],
      events: [],
      elementsX: 0,
      elementsY: 0,
      rowElements: []
    }
  },

  created: function() {
    this.getActiveEvents()
    this.getPositions()
  },

  methods: {
    getActiveEvents: function() {
      this.$http.get("/event")
      .then(resp => {
        if (resp.data.events != null){
          this.events = resp.data.events.filter(function (event) {
            return (event.status == 'active')
          })
        }
        
        if (this.events != null){
          this.events.forEach(event => {
            this.getRoomName(event.id, event.room)
            this.getImage(event.id, event.image_url)
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        
      })
    },

    getRoomName: function(eventId, roomId) {
      this.$http.get("/room/" + roomId)
      .then(resp => {
        this.events.find(event => event.id == eventId).room = resp.data.room.name
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })  
    },

    getImage: function(eventId, url) {
      this.$http.get(url, {responseType: 'blob'})
      .then(resp => {
        var oFReader = new FileReader()

        oFReader.onload = (oFREvent => {
          const index = this.events.findIndex(event => event.id == eventId)
          this.$set(this.events, index, Object.assign({}, this.events[index], { image: oFREvent.target.result }))
        })

        oFReader.readAsDataURL(resp.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })
    },

    getPositions: function() {
      this.$http.get("/position")
      .then(resp => {
        if(resp.data.positions != null){
          this.loadedEventPositions = resp.data.positions
          this.rowElements = resp.data.grid
        }

        
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.createSortable()
      })
    },

    savePositions: function(e) {
      e.preventDefault()

      const data = {"positions": this.serializePreview()}

      this.$http.post("/position", data)
      .then(() => {
        
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })
    },

    createSortable: function() {
      for (let y = 0; y < this.rowElements.length; y++) {
        var el = document.getElementById('row-' + (y + 1));

        Sortable.create(el, {
          swap: true,
          swapClass: 'highlight',
          group: 'shared',
          animation: 150,

          onEnd: function (/**Event*/evt) {
            var draggedItem = evt.item
            var swappedItem = evt.swapItem

            var temp = draggedItem.className
            draggedItem.className = swappedItem.className
            swappedItem.className = temp
          },
        });
      }
    },

    renderEventByPosition: function(x,y) {
      var result = []
       
      if(this.loadedEventPositions.length) {
        var currentEventId = this.loadedEventPositions.filter(function (event) {
          return(event.pos_x == x & event.pos_y == y) 
        })[0].event_id

        result = this.events.filter(function (event) {
          return (event.id == currentEventId)
        })[0]
      }

      return result
    },

    calcColumn: function(rowElements) {
      return 12 / rowElements
    },

    serializePreview: function() {
      var preview = document.getElementById('preview')
      var eventPosition = []

      //console.log(preview)

      for (let yIndex = 1; yIndex <= this.rowElements.length; yIndex++) {
        const eventRow = preview.querySelector('#row-' + yIndex)
        //console.log(eventRow)
        for (let xIndex = 1; xIndex <= this.rowElements[yIndex - 1]; xIndex++) {
          const eventColumn = eventRow.getElementsByClassName(xIndex)[0]
          const eventTitle = eventColumn.getElementsByClassName('card-title')[0].innerText
          
          //console.log(eventColumn)
          //console.log(eventTitle)

          const eventId = this.events.filter(function (event) {
            return (event.name == eventTitle)
          })[0].id

          //console.log(eventId)
          eventPosition.push(
            {
              "event_id": eventId,
              "pos_x": xIndex,
              "pos_y": yIndex
            }
          )
        }
      }

      return eventPosition
    },

    convertTimestamp: function(timestamp) {
      var date = new Date(timestamp * 1000)
      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!

      var datum = dd + '.' + mm + '.'
      var time = ("0" + date.getHours()).slice(-2) + ":" + (date.getMinutes()<10?'0':'') + date.getMinutes();

      return datum + ' ' + time;
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 1rem;
}

.title {
  padding: 0.7rem 1rem;
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  border-bottom: 2px solid #000;
}

@include media-breakpoint-down(md) {
  #preview {
    font-size: 80%;
  }
}

.card {
  margin: auto;
  width: 14em;
  min-height: 15em;
  border-radius: 1rem;
}

.card-title {
  margin-bottom: 0rem;
}

.card-body h3 {
  font-size: 1.75em;
}

.card-text {
  float: left;
}

.card-text h5 {
  float: left;
  line-height: inherit;
  font-size: 1.25em;
}

.card-text span {
  font-size: 1.25em;
  margin-left: 0.5em;
}

.highlight {
  background-color: $body-bg;
}

.direction svg {
  font-size: 3em;
}
</style>