<template>
  <div class="container h-100 d-flex flex-column">
    <div class="row">
      <div class="col">
        <div class="title" v-if="roomId != undefined">Raum {{ roomId }} bearbeiten</div>
        <div class="title" v-else>Neuen Raum erstellen</div>
      </div>
    </div>
    <div class="row mt-3 flex-grow-1">
      <div class="col overflow-auto">
        <div class="container form-container">
          <form @submit="submitForm">
            <div class="form-group">
              <label for="inputRoomId">Raum ID:</label>
              <div class="row">
                <div class="col-4">
                  <input type="text" class="form-control" id="inputRoomId" v-model="inputRoomId" required>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="inputRoomName">Name:</label>
              <div class="row">
                <div class="col-4">
                  <input type="text" class="form-control" id="inputRoomName" v-model="inputRoomName" required>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="inputSelectFloor">Etage:</label>
              <div class="row">
                <div class="col-3">
                  <select class="form-control" id="inputSelectFloor" v-model="inputSelectFloor" required>
                    <option value="9">Untergeschoss</option>
                    <option value="10">Erdgeschoss</option>
                    <option value="11">1. Etage</option>
                    <option value="12">2. Etage</option>
                    <option value="13">3. Etage</option>
                    <option value="14">4. Etage</option>
                  </select>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary mb-3">{{ buttonAction }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    const roomId = this.$route.params.id;

    if (roomId == undefined) {
      return {
        roomId,
        inputRoomId: "",
        inputRoomName: "",
        inputSelectFloor: 10,
        buttonAction: "Erstellen"
      } 
    } else {
      //TODO, Load Event Data and put into Forms
      return {
        roomId,
        inputRoomId: "",
        inputRoomName: "",
        inputSelectFloor: 10,
        buttonAction: "Speichern"
      }
    }
  },

  created() {
    if (this.roomId != undefined){
      this.getRoom(this.$route.params.id)
    }
  },

  methods: {
    submitForm: function(e) {
      e.preventDefault()

      const data = {
        "room_id": this.inputRoomId,
        "name": this.inputRoomName,
        "floor": parseInt(this.inputSelectFloor)
      }

      if(this.buttonAction == "Erstellen"){
        this.createRoom(data)
      } else if (this.buttonAction == "Speichern"){
        this.updateRoom(data)
      }
    },

    getRoom: function(roomId) {
      this.$http.get("/room/" + roomId)
      .then(resp => {
        this.inputRoomId = resp.data.room.room_id
        this.inputRoomName = resp.data.room.name
        this.inputSelectFloor = resp.data.room.floor
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })
    },

    createRoom: function(data) {
      this.$http.post("/room", data)
      .then(() => {
        this.$router.push({name: "roomList"});
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })
    },

    updateRoom: function(data) {
      this.$http.put("/room/" + this.roomId, data)
      .then(() => {
        this.$router.push({name: "roomList"});
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {

      })
    }
  }
}
</script>

<style lang="scss" scoped>
.overflow-auto {
  overflow-x: hidden !important;
}

.title {
  padding: 0.7rem 1rem;
  text-align: left;
  font-size: 1.5rem;
  font-weight: bold;
  width: 100%;
  border-bottom: 2px solid #000;
}

.form-container {
  position: absolute;
}
</style>