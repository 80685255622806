<template>
  <div class="logo">
    <router-link to="/">
      <div class="header align-items-center justify-content-center d-flex">
        <img src="../assets/logo.svg">
        <h1>Events</h1>
      </div>
    </router-link>
  </div>
</template>


<script>
export default {
  name: "logo"
};
</script>

<style lang="scss" scoped>
.logo a {
  color: inherit;
  text-decoration: none;
}

.logo a:visited {
  color: inherit;
  text-decoration: none;
}

.logo a:hover {
  color: #000;
  text-decoration: none;
}

.header {
  width: 100%;
  margin-bottom: 2rem;
}

.header > img {
  height: 7rem;
}

.header > h1 {
  margin-left: 1rem;
  margin-bottom: 0;
}
</style>
